/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


  
jQuery(function($) {  
$(document).ready(function(){
  //Setting hero container to occupy full viewport height
  $(".hero").css({"height":document.documentElement.clientHeight});
  
  //functions to set, get, check and delete cookies
  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
  }
  
  function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0)===' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length,c.length);
      }
    }
    return "";
  }
  
  function checkCookie(cname) {
    var location = getCookie(cname);
    if (location !== "") {
      return true;
    } else {
      return false;
    }
  }
  
  function deleteCookie(cname) {
    document.cookie = cname+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
  }
  
  //Various functions on page load: Location dropdown display after 5s, check and get cookie value
  $(window).load(function(){
    
    
    
    if(!checkCookie("mielelocation")){
      if($(".expCDefaultHero").length>0){
        $(".expCLocatedHero").hide();
        $(".expCDefaultHero").fadeIn();
      }
      setTimeout(function(){
        if(!$(".fLocationText").hasClass("selected")&&!$(".expCLocation").hasClass("selected")){
          $(".hLocationTrigger").toggleClass("active");
          $(".headLocationOuter").fadeIn(); 
        }
      },5000);
    }
    else{
      var location=getCookie("mielelocation");
      if($(".expCDefaultHero").length>0){
        $(".expCDefaultHero").hide();
        $(".expCLocatedHero").fadeIn().addClass("Located");
      }
      $(".expCDropTrigger p").text(location);
      $(".hLocationDropTrigger p.hLocation").text(location);
      $(".fLocationText").text(location);
    }
    if(document.documentElement.clientWidth>768&&$("body.home").length>0)
    {
      //$("#video1").attr("loop","");
      var vid = document.getElementById("video1");
      vid.play();     
    }
    if(document.documentElement.clientWidth>768&&$(".bottomVideos").length>0){
      var vidL = document.getElementById("appVideoLeft");
      var vidR = document.getElementById("appVideoRight");
      //vidL.play();
      //vidR.play();
    }
    $(".recipeSlider .owl-next,.recipeFeatureSlider .owl-next").hide();
    if($("body.home").length===0) {
      $(".main").css({paddingTop:$("header").outerHeight()});
    }
    $(".eventsTab2").hide();
    $(".eventsTabHolder").css({opacity:1});
  });

  
  function autoplayVideo(){
    if($(document).scrollTop()+$(window).outerHeight()>$(".bottomVideos").offset().top){
      var vidL = document.getElementById("appVideoLeft");
      var vidR = document.getElementById("appVideoRight");
      vidL.play();
      vidR.play();
    }
  }
  
  //Sticky Header
  $(window).scroll(function(e) {
    if ($(this).scrollTop() > 250){  
      $('header').addClass("sticky");
    }
    else{
      $('header').removeClass("sticky");
    }
    if($(".bottomVideos").length>0) {
      autoplayVideo();
    }
  });
  



/*
  
$(window).load(function() { 
  
  
var stickyHeadlineTop = $('.expCLocatedHeroText').offset().top; 
var heroLocated = $('.expCLocatedHero').offset().top;
var heroHeadline= $('.LocatedHeroHeadline').offset().top;
var heroDemo= $('.upcomingDemoEvents').offset().top;
    
    var stickyHeadline = function(){
      
    var scrollTop = $(this).scrollTop();  
    var fadeStart = 50;
    var fadeUntil = 200;
    if (scrollTop > stickyHeadlineTop) { 
    //var scrll = $(this).scrollTop();
    //  var size = 700;   
    
    //parallax scroll effect
    //if($('.expCLocatedHero').height()>400){
      
    //}
      //if($('.expCLocatedHero').height()>400){
      
    //}
      //if($('.expCLocatedHero').height()>scrollTop&&heroLocated<scrollTop){
      //fading.css('opacity',opacity).html(opacity);
      
       if( scrollTop<=fadeStart ){
        height1=700;
    }else if( scrollTop<=fadeUntil ){
        height1=700-scrollTop/fadeUntil;
    }
      //$('.expCLocatedHero').height(($('.expCLocatedHero').height())-(scrollTop));
      $('.expCLocatedHero').css('height',height1).html(height1);    
      //  if($(this).scrollTop() > 20){
      //    $('.expCLocatedHeroText').css({"position":"fixed",top: 0,zIndex: 10000});
      //    $(".heroHideSection").slideUp(scrollTop);
      //  }
        
        
        //400
      //  if($(this).scrollTop() > 150){
      //    $(".LocatedHeroHeadline br").css({"display":"none"});
      //    $(".LocatedHeroHeadline").slideUp(scrollTop*20);
          //$(".LocatedHeroHeadline").css({opacity:scrollTop});
      //  }
    
      //}
    
    //if($('.expCLocatedHero').height()<700&&scrollTop<20){
    //$('.expCLocatedHero').height(($('.expCLocatedHero').height())+(scrollTop));
    
    //}
    //if($('.expCLocatedHero').height()<700){
    //  $('.expCLocatedHero').height(scrollTop+2);
  
    //  }
      /*
      
      //100
      if($(this).scrollTop() > 50){
      
      $(".heroHideSection").slideUp(scrollTop);
      }
      //400
      if($(this).scrollTop() > 150){
      $(".LocatedHeroHeadline br").css({"display":"none"});
      
      }
      //500
      if($(this).scrollTop() > 270){
      $(".LocatedHeroHeadline").slideUp(scrollTop);
      //$('.expCLocatedHero').css({"backgroundAttachment": "relative",top: 0,zIndex:-1});
      //$('.upcomingDemoEvents').css({"position": "relative",zIndex:0});
      }
    
      //500
      
          if($(this).scrollTop() < 250){
      $(".LocatedHeroHeadline").fadeIn(scrollTop);
      
      }
      */
/*    
    }

    
    else {
    
    
          if(heroDemo>scrollTop){
      
      $('.expCLocatedHero').height(($('.expCLocatedHero').height())+(scrollTop));
          
      
      
    
      }
    
    
    //if($('.expCLocatedHero').height()<700){
      
  //    $('.expCLocatedHero').height(scrollTop+1);
  //  }
      
      //$('.expCLocatedHero').height(scrollTop+10);
      //$('.expCLocatedHero').css({"height":"initial",top: 0,zIndex: 10000,"transition":"all 0.3s ease"});
      
      
      //$(".LocatedHeroHeadline br").css({"display":"block"});
      //$(".heroHideSection").fadeIn(500);      
      //$('.expCLocatedHeroText').css({"position": "relative", top: 0,zIndex: 10000,"transition":"height 0.3s ease"});
    
      }
  
    };

 
stickyHeadline();
 
$(window).scroll(function() {
  stickyHeadline();
});

});
  
    
  
    
    

    
    $(window).scroll(function(e) {
  if ($(this).scrollTop() > 50){  
    
    //$(".expCLocatedHeroText").addClass("stickyHeadline");
    //var section=$(this).attr('data-scroll-section');
    //$("html,body").animate({scrollTop:$("."+section).offset().top-$("header").outerHeight()},800);
    //$(".locationAddress").fadeOut(600);   
    //$(".callTourLink").fadeOut(600);    
    //$(".locationTimings").fadeOut(600);
    $(".heroHideSection").slideUp(2000);
    //$(".expCLocatedHero").css({transition:"all 0.6s ease"});
     $(".expCLocatedHero").addClass("stick");
    }
   if ($(this).scrollTop() < 50){  
    //$(".expCLocatedHero").addClass("sticky");
    //$(".locationAddress").fadeIn(600);
    //$(".callTourLink").fadeIn(600);
    //$(".locationTimings").fadeIn(600);
    $(".heroHideSection").slideDown(1500);
    $(".expCLocatedHero").removeClass("stick");
    }
    else{
    //$(".expCLocatedHero").removeClass("sticky");
    }
    
  });
  
  */
  
  //Mega Menu display
  $(".navbar ul li.has-child").hover(function(){
    $(".megaMenu").stop().fadeIn();
  },function(){
    $(".megaMenu").stop().fadeOut();
  });
  
  
  $(".hLocationTrigger").click(function(event){
    event.preventDefault();
  });
  
  //Video Fullscreen mode trigger
  function fullScreenVideo(id){
    var vid = document.getElementById(id);
    if (vid.requestFullscreen) {
      vid.requestFullscreen();
    } else if (vid.mozRequestFullScreen) {
      vid.mozRequestFullScreen();
    } else if (vid.webkitRequestFullscreen) {
      vid.webkitRequestFullscreen();
    } 
  }
  $(".videoPlayTrigger").click(function(){
    fullScreenVideo("video1");
  });
  
  $(".videoLPlayTrigger").click(function(){
    fullScreenVideo("appVideoLeft");
  });
  
  $(".videoRPlayTrigger").click(function(){
    fullScreenVideo("appVideoRight");
  });
  
  //Header Location Dropdown
  $(".hLocationDropTrigger").click(function(){
    $(".hLocationDropDown").slideToggle();
  });
  
  //Header Location Dropdown selection
  $(".hLocationDropDown ul li a").click(function(){
    deleteCookie("mielelocation");
    $(".hLocationDropTrigger p.hLocation").text($(this).text());
    $(".fLocationText").text($(this).text());
    $(".expCDropTrigger p").text($(this).text());
    setCookie("mielelocation",$(this).text(),365);
    $(".hLocationDropDown").slideUp();
    setTimeout(function(){
      $(".headLocationOuter").fadeOut(200);
      $(".hLocationTrigger").removeClass("active");
    },1000);
  });
  
  //Footer Location Dropdown
  $(".fLocationText,.fLocationArrow").click(function(){
    if($(this).hasClass('activeDrop')) {
      $(".fLocationDropDown").hide();
      $(this).removeClass('activeDrop');
    } 
    else  
    {
      $(".fLocationDropDown").fadeIn();
      $(this).addClass('activeDrop');
    } 
  });
  
  //Footer Location Dropdown selection
  $(".fLocationDropDown ul li a").click(function(){
    deleteCookie("mielelocation");
    $(".fLocationText").text($(this).text());
    setCookie("mielelocation",$(this).text(),365);
    $(".fLocationText").addClass("selected");
    $(".fLocationDropDown").hide();
    $(".expCDropTrigger p").text($(this).text());
    $(".hLocationDropTrigger p.hLocation").text($(this).text());
    $(".hLocationTrigger.active").removeClass("active");
    $(".headLocationOuter").hide(); 
  });
  
  //Scrolling to next section
  $(".heroScrollTo,.heroScrollText").click(function(){
    var section=$(this).attr('data-scroll-section');
    $("html,body").animate({scrollTop:$("."+section).offset().top},800);
  });
  
  $(".scrollTo").click(function(){
    var section=$(this).attr('data-scroll-section');
    $("html,body").animate({scrollTop:$("."+section).offset().top-$("header").outerHeight()},800);
  });
  
  //Mobile Menu Icon
  $(".mobileMenuTrigger a").click(function(event){
    event.preventDefault();
    $(".mobileMenu").css({top:$("header").outerHeight()}).slideToggle();
  });
  
  //Recipe Slider
  $('.owl-carousel').owlCarousel({
    loop:true,
    nav:true,
    navText: [
    "prev",
    "<img src='http://188.226.237.233/shared/mieleexperience/wp-content/themes/mieleexperience/dist/images/reciepeslide-more.png' width='65' class='recipeSlideMore'>"
    ],
    dots:false,
    addClassActive:true,
    responsive:{
      0:{
        items:1,
        stagePadding: 20
      },
      480:{
        items:2,
        stagePadding: 20
      },
      600:{
        items:3,
        stagePadding: 35
      },
      840:{
        items:3,
        stagePadding: 50
      },
      960:{
        items:3,
        stagePadding: 80
      },
      1150:{
        items:4,
        stagePadding: 80
      },
      1280:{
        items:4,
        stagePadding: 80
      },  
      1600:{
        items:5,
        stagePadding: 80
      },
      1920:{
        items:5,
        stagePadding: 80
      },
      2560:{
        items:5,
        stagePadding: 150
      }
    }
  });
  
  //Recipe slide triggering click for the whole slide
  $(".recipeSlide").click(function(event){
    var link=$(this).attr("data-link");
    if(link&&!$(event.target).hasClass("fa-thumbs-o-up")) {
      window.location=$(this).attr("data-link");
    }
  });
  
  //Home page Recipe more arrow
  $(".recipeSlider").hover(function(){
    $(".recipeSlider .owl-next").stop().fadeIn();
    $(".recipeSlideMore").fadeIn();
  },function(){
    $(".recipeSlider .owl-next").stop().fadeOut();
  });
  
  //Demonstrations carousel arrow
  $(".eventsTab").hover(function(){
    $(".eventsTab .owl-next").stop().fadeIn();
    $(".recipeSlideMore").fadeIn();
  },function(){
    $(".eventsTab .owl-next").stop().fadeOut();
  });
  
  //recipes arrow
  $(".recipeFSlide").hover(function(){
    $(".recipeFSlide .owl-next").stop().fadeIn();
    $(".recipeSlideMore").fadeIn();
  },function(){
    $(".recipeFSlide .owl-next").stop().fadeOut();
  });
  
  
  //Mobile Menu
  $("#mobileMenuList").navgoco({
    caretHtml: '',
    accordion: false,
    openClass: 'open',
    save: true,
    cookie: {
      name: 'navgoco',
      expires: false,
      path: '/'
    },
    slide: {
      duration: 400,
      easing: 'swing'
    },
        // Add Active class to clicked menu item
        //onClickAfter: active_menu_cb,
      });
  
  function viewport() {
    var e = window, a = 'inner';
    if (!('innerWidth' in window )) {
      a = 'client';
      e = document.documentElement || document.body;
    }
    return { width : e[ a+'Width' ] , height : e[ a+'Height' ] };
  }
  
  function hideMobileMenu(){
    var vw=viewport();
    if(vw.width>1024){
      $(".mobileMenu").removeClass('active').hide();
    }
  }
  
  $(window).resize(function(){
    hideMobileMenu();
    restoreFilters();
    if($(".eventSessionWrap").outerHeight()>$(".appDemosLoaded").height()) {
      $(".demoEventsContainer").css({height:$(".eventSessionWrap").outerHeight()+15});
    }
    else if($(".eventSessionWrap").outerHeight()<$(".demoEventLeft").outerHeight()&&$(".eventSessionDetails").hasClass("active")) {
      $(".demoEventLeft").css({height:$(".eventSessionWrap").outerHeight()+15});
    }
  });
  
  function restoreFilters(){
    var vw=viewport();
    if(vw.width>960){
      $(".demoEventRight").css({right:0}).show();
      $(".filterBackOverlay").fadeOut();
    }
    else{
      $(".demoEventRight").css({right:-$(".demoEventRight").outerWidth()}).show().animate({right:0},800);
            $(".demoEventRight").addClass('active');
      $(".filterBackOverlay").fadeIn();
    }
  }
  
  $('.recipeFeatureSlider').owlCarousel({
    loop:true,
    nav:true,
    navText: [
    "prev",
    "<img src='http://188.226.237.233/shared/mieleexperience/wp-content/themes/mieleexperience/dist/images/reciepeslide-more.png' width='65' class='recipeSlideMore'>"
    ],
    dots:false,
    addClassActive:true,
    responsive:{
      0:{
        items:1,
        stagePadding: 50
      },
      480:{
        items:2,
        stagePadding: 20
      },
      600:{
        items:2,
        stagePadding: 50
      },
      840:{
        items:3,
        stagePadding: 50
      },
      960:{
        items:3,
        stagePadding: 50
      },
      1150:{
        items:3,
        stagePadding: 50
      },
      1280:{
        items:3,
        stagePadding: 50
      },  
      1300:{items:4,
        stagePadding: 50
      },
      1600:{
        items:4,
        stagePadding: 50
      },
      1800:{
        items:5,
        stagePadding: 50
      },
      2200:{
        items:5,
        stagePadding: 100
      },
      2560:{
        items:5,
        stagePadding: 150
      }
    }
  });
  
  $(".recipeFeatureSlider").hover(function(){
    $(".recipeFeatureSlider .owl-next").stop().fadeIn();
  },function(){
    $(".recipeFeatureSlider .owl-next").stop().fadeOut();
  });
  
  $(".recipeGridItem").hover(function(){
    $(this).find(".recipeItemHOverlay").stop().fadeIn();
  },function(){
    $(this).find(".recipeItemHOverlay").stop().fadeOut();
  });
  
  $(".searchRecipe").click(function(){
    $(".recipeSearchOverlay").fadeIn().addClass("rSOActive");
    $(".recipeSearch input").focus();
  });
  
  $(".expCDropTrigger").click(function(){
    $(".expCLocationDropDown").slideToggle();
  });
  
  $(".expCLocationDropDown ul li a").click(function(){
    deleteCookie("mielelocation");
    $(".expCDropTrigger p").text($(this).text());
    $(".hLocationDropTrigger p.hLocation").text($(this).text());
    $(".headLocationOuter").hide();
    $(".hLocationTrigger").removeClass("active");
    $(".fLocationText").text($(this).text());
    setCookie("mielelocation",$(this).text(),365);
    $(".expCLocationDropDown").slideUp();
    $(".expCLocation").addClass("selected");
    setTimeout(function(){
      $(".expCDefaultHero").hide();
      $(".expCLocatedHero").fadeIn().addClass("Located");
    },1000);
  });
  
  $(".eventInfoIcon").click(function(){
    $(this).siblings(".eventInfoOverlay").fadeIn();
  });
  
  $(".eventInfoOverlayClose").click(function(){
    $(this).parent().fadeOut();
  });
  
  /* Filter for Events */
  /*
  $(".filterDemoOption").click(function(event){
    $(".filterDemoOption").removeClass("active");
    $(this).addClass("active");
    event.preventDefault();
  });
  */
  
  var dateToday = new Date();
  $(".eventDatePicker,.eSessionDatePicker").datepicker({minDate: dateToday});
  
  $(document).on("click",".eventDatePickerOuter .fa-calendar",function(e){
    $(this).siblings(".eventDatePicker").focus();
  });
  
  $(document).on("click",".FBCLocationText,.FBCLocationArrow",function(e){
    if($(this).hasClass('activeDrop')) {
      $(".FBCLocationDropDown").hide();
      $(this).removeClass('activeDrop');
    } 
    else  
    {
      $(".FBCLocationDropDown").fadeIn();
      $(this).addClass('activeDrop');
    } 
  });
  
  $(document).on("click",".eFBCLocationText,.eFBCLocationArrow",function(e){
    if($(this).hasClass('activeDrop')) {
      $(".eFBCLocationDropDown").hide();
      $(this).removeClass('activeDrop');
    } 
    else  
    {
      $(".eFBCLocationDropDown").fadeIn();
      $(this).addClass('activeDrop');
    } 
  });
  
  $(".FBCLocationDropDown ul li").click(function(){
    $(".FBCLocationText").text($(this).text());
    $(".FBCLocationDropDown").hide();
  });
  
  $(".eFBCLocationDropDown ul li").click(function(){
    $(".eFBCLocationText").text($(this).text());
    $(".eFBCLocationDropDown").hide();
  });
  
  $("#appDemos .demoLinkButton").click(function(event){
    event.preventDefault();
    $(".demoEventsGrid,.filterDemoSidebar").fadeIn();
    $(".appDemosLoaded").slideDown();
    //$(".eventSessionDetails").hide();
    //$(".eventSessionDetails").addClass("eventDeActive");
    $("html,body").animate({scrollTop:$(".appDemosLoaded").offset().top-$("header").outerHeight()-200},1000);
  });
  
  $(".eventHOverlay a").click(function(event){
    event.preventDefault();
    //$(".demoEventsGrid,.demoEventRight,.triggerFilters").slideUp(800);
    //$(".demoEventRight").addClass("hidden");
    //$(".eventSessionWrap").fadeIn(300);
    //$(".eventSessionDetails").addClass("active");
    //$(".eventSessionDetails").css({"height":"700px","display":"inline", "top":"0px"});
    
    $(".eventSessionDetails").show();
    /*
    $(".eventSessionDetails").addClass("active").slideDown(600, function(){
      if($(".eventSessionWrap").outerHeight()>$(".appDemosLoaded").height())
      $(".demoEventsContainer").css({height:$(".eventSessionWrap").outerHeight()+15});
      else if($(".eventSessionWrap").outerHeight()<$(".demoEventLeft").outerHeight()&&$(".eventSessionDetails").hasClass("active")) 
      $(".demoEventLeft").css({height:$(".eventSessionWrap").outerHeight()+15});
    });
    */
  });
  
  /* Cart funtions */
  //$(".addEventIcon").click(function(){
  jQuery(document).on("click",".addEventIcon",function(e){
    $(".eCartOuter").animate({bottom:(-480+$(".eCartHeader").outerHeight())});
    
  });
  
  //$(".eCartHeader").click(function(){
  jQuery(document).on("click",".eCartHeader",function(e){
    if($(".eCartOuter").hasClass("eCartOpen")){
      $(".eCartOuter").removeClass("eCartOpen").animate({bottom:(-480+$(".eCartHeader").outerHeight())});
      $(".eCartOverlay").fadeOut();
    }
    else {
      $(".eCartOuter").addClass("eCartOpen").animate({bottom:0});
      $(".eCartOverlay").fadeIn().addClass("rSOActive");
    }
  });
  
  //$(".eCartItemRemove").click(function(){
  jQuery(document).on("click",".eCartItemRemove",function(e){
    $(".eCartOuter").removeClass("eCartOpen").animate({bottom:(-480+$(".eCartHeader").outerHeight())});
  });
  
  
  
  
  $(".demoTabHead").click(function(event){
    event.preventDefault();
    if(!$(this).hasClass("active")){
      $(".demoTabHead").removeClass("active");
      $(".demoTabHead.active").removeClass("active");
      $(".eventsTab.active").hide().removeClass("active");
      var tab=$(this).attr("data-target");
      $(this).addClass("active");
      $("."+tab).addClass("active").fadeIn(600);
    }
  });
  
  $(".recipeSOverlayClose").click(function(){
    
    $(".recipeSearchOverlay").fadeOut();
    
  });
  
  $(".recipeSearch input").focusout(function(){
    
    $(".recipeSearchOverlay").fadeOut();
    
  });

  $(document).keyup(function(e) {
    if (e.keyCode === 27) { 
      if($(".recipeSearchOverlay").hasClass("rSOActive")){
        $(".recipeSearchOverlay").fadeOut().removeClass("rSOActive");
      }
    }
  });
  
  $(".eCartOverlay").click(function(){
    if($(".eCartOuter").hasClass("eCartOpen")){
      $(".eCartOuter").removeClass("eCartOpen").animate({bottom:(-480+$(".eCartHeader").outerHeight())});
      $(".eCartOverlay").fadeOut();
    }

    
  });
  $(document).keyup(function(e) {
    if (e.keyCode === 27) { 
      if($(".eCartOverlay").hasClass("rSOActive")){
        $(".eCartOverlay").fadeOut().removeClass("rSOActive");
      }
    }
  });
  
  /*
  jQuery(document).on("click", ":not(.triggerFilters)", function(e){ 

    //$(".demoEventRight").css({right:-$(".demoEventRight").outerWidth()}).show().animate({right:0},800);
    //$(".filterBackOverlay").fadeIn();
    //$("body,html").animate({scrollTop:$(".appDemosLoaded").offset().top-$("header").outerHeight()},500);
    //restoreFilters();
    console.log('document click close if active');

    var active = $('.demoEventRight').hasClass('active');

    if (active && (this.id != 'demoEventRight')) {
       $(".demoEventRight").animate({right:-$(".demoEventRight").outerWidth()},800).fadeOut().css({right:0});
    }
  }); 

  function showDemoEventRight() {
      $(".demoEventRight").css({right:-$(".demoEventRight").outerWidth()}).show().animate({right:0},800);
      $(".demoEventRight").addClass('active');
      $(".filterBackOverlay").fadeIn();
  }
  */
  $(".triggerFilters").click(function(){
      console.log('triggerFilters');
    $(".demoEventRight").css({right:-$(".demoEventRight").outerWidth()}).show().animate({right:0},800);
    $(".demoEventRight").addClass('active');
    $(".filterBackOverlay").fadeIn();
    //$("body,html").animate({scrollTop:$(".appDemosLoaded").offset().top-$("header").outerHeight()},500);
  }); 
  
  $(".filterBackOverlay").click(function(){
    $(".demoEventRight").animate({right:-$(".demoEventRight").outerWidth()},800).fadeOut().css({right:0});
    $(this).fadeOut();
    $("body").css({overflow:"initial"});
  });
  
  function backToEvents(){
    $(".demoEventsContainer,.demoEventLeft").css({height:"initial"})
    //$(".eventSessionDetails").slideUp(600).removeClass("active"); 
    //$(".eventSessionDetails").css({height:0});
    //$(".eventSessionDetails").removeClass("eventActive");//.css({"height":0});
      //$(".eventSessionDetails").css({"height":"700px", "top":"-700px", "transition":"all 0.3s ease-out"});
      $(".eventSessionDetails").hide();
    }



    $(document).on("click", '.eventSessionBack img', function(event) { 
        backToEvents();
    });

    $(document).on("click", '.demoSearchBContainer', function(event) { 
        backToEvents();
    });

  

  /*if($('.expCLocatedHero').length>0){
  var stickyTop = $('.expCLocatedHero').offset().top;

  
    $(window).scroll(function() {
    if($('.expCLocatedHero').hasClass("Located")){
    var windowTop = $(window).scrollTop();
    if (stickyTop < windowTop) {
      $('.expCLocatedHero').css({position: 'fixed',width:"100%",zIndex:"9",minHeight:"initial"});
      $(".expCLocatedHeroText").css({padding:"30px 0 30px 10%"});
      $(".locationAddress,.expCLocatedHeroText a,.locationTimings").slideUp(600,function(){
      if(!$(".upcomingDemoEvents").hasClass("heroSticky")) {
        $(".upcomingDemoEvents").css({marginTop:$('.expCLocatedHero').outerHeight()-10}).addClass("heroSticky");
        if($(document).scrollTop()<=100)
          $("body,html").animate({scrollTop:"20"},800);
      } 
     });
    } else {
      $('.expCLocatedHero').css('position', 'relative');
      $(".expCLocatedHeroText").css({padding:"140px 0 100px 10%"});
      $(".locationAddress,.expCLocatedHeroText a,.locationTimings").slideDown(600);
      $(".upcomingDemoEvents").css({marginTop:"initial"}).removeClass("heroSticky");
    }
    }
    });   
  }*/


    /* BOF Experience Center Filters */

    var options = {
      valueNames: [ 'eventSessionName', 'eventInfoOverlayText', 'eventCategory' ]
    };

    var featureList = new List('demoEventsGrid', options);


    $('.demoSearch').keyup(function() {
      featureList.search($(this).val());
    });

    $('#filter-prepurchase').click(function() {
      event.preventDefault();
      filterPrepurchase();
    });

    $('#filter-owner').click(function() {
      event.preventDefault();
      filterOwner();
    });
    $('#filter-master').click(function() {
      event.preventDefault();
      filterMaster();
    });
    $('#filter-none').click(function() {
      event.preventDefault();
      filterAll();
    });

    var urlfilter = getParameterByName('filter');
    //console.log(urlfilter);

    if (urlfilter) {
      if (urlfilter === 'prepurchase') {
        filterPrepurchase();
      } else if (urlfilter === 'owner') {
        filterOwner();
      } else if (urlfilter === 'master') {
        filterMaster();
      } else {
        filterAll();
      }
      window.location.hash = '#demoEventsGrid';
    }

    function filterUpdateQuantityShowed() {
        var newquantity = featureList.visibleItems.length;

        jQuery('.entriesFilteredCount').text(newquantity);
    }

    function filterPrepurchase() {

      $('#filter-prepurchase').parent().siblings().removeClass('active');
      $('#filter-prepurchase').parent().addClass('active');
      featureList.filter(function(item) {
          if (item.values().eventCategory == "Pre-Purchase Demonstrations") {
              console.log(featureList.visibleItems.length);
              return true;
          } else {
            return false;
          }
      });
      filterUpdateQuantityShowed();
      return false;
    }

    function filterOwner() {
      $('#filter-owner').parent().siblings().removeClass('active');
      $('#filter-owner').parent().addClass('active');
        featureList.filter(function(item) {
          if (item.values().eventCategory == "Owner Demonstrations") {
              return true;
          } else {
            return false;
          }
        });
        filterUpdateQuantityShowed();   
        return false;

    }

    function filterMaster() {
      $('#filter-master').parent().siblings().removeClass('active');
      $('#filter-master').parent().addClass('active');
        featureList.filter(function(item) {
          if (item.values().eventCategory == "MasterClasses") {
              return true;
          } else {
            return false;
          }
        });
        filterUpdateQuantityShowed();
        return false;
    }

    function filterAll() {
      $('#filter-none').parent().siblings().removeClass('active');
      $('#filter-none').parent().addClass('active');
        featureList.filter();
        filterUpdateQuantityShowed();
        return false;
    }

    /* EOF Experience Center Filters */


});

});

function getParameterByName(name, url) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_experience_centre_page': {
      init: function() {
            //Hero shrink
        $(function () { 
            // init controller
            var controller = new ScrollMagic.Controller();

          // build tween


          var scene1 = new ScrollMagic.Scene({
              //duration: 800,    // the scene should last for a scroll distance of 100px
                 // offset: 100        // start this scene after scrolling for 50px
              //triggerElement: '.upcomingDemoEvents',
              //triggerHook: 0,
              duration: 470,
              offset: 10
            })
            //.setTween(tween)
            .setPin('.expCLocatedHeroText')
            //.setClassToggle('#LocatedHeroHeadline', 'fade-out') // pins the element for the the scene's duration
            //.addIndicators({name: "stick"})
              .addTo(controller); // assign the scene to the controller
          //var tween = TweenMax.to(".expCLocatedHero", 0.5, {scale: 3, ease: Linear.easeNone});
          var scene = new ScrollMagic.Scene({
              //duration: 800,    // the scene should last for a scroll distance of 100px
                 // offset: 100        // start this scene after scrolling for 50px
              //triggerElement: '.upcomingDemoEvents',
              //triggerHook: 0,
              //duration: 400,
              offset: 500
            })
            //.setTween(tween)
            .setPin('.exp', {pushFollowers: false})
            //.setClassToggle('#LocatedHeroHeadline', 'fade-out') // pins the element for the the scene's duration
            //.addIndicators({name: "stick"})
              .addTo(controller); // assign the scene to the controller

              var scene1 = new ScrollMagic.Scene({
              //duration: 800,    // the scene should last for a scroll distance of 100px
                  offset: 100        // start this scene after scrolling for 50px
              //triggerElement: '.upcomingDemoEvents'
            })
            //.setTween(tween)
            //.setPin('.expCLocatedHero', {pushFollowers: false})
              .setClassToggle('#locationTimings', 'fade-out') // pins the element for the the scene's duration
            //.addIndicators({name: "fade"})
              .addTo(controller); // assign the scene to the controller

              var scene2 = new ScrollMagic.Scene({
              //duration: 800,    // the scene should last for a scroll distance of 100px
                  offset: 200        // start this scene after scrolling for 50px
              //triggerElement: '.upcomingDemoEvents'
            })
            //.setTween(tween)
            //.setPin('.expCLocatedHero', {pushFollowers: false})
              .setClassToggle('#callTourLink', 'fade-out') // pins the element for the the scene's duration
            //.addIndicators({name: "fade"})
              .addTo(controller); // assign the scene to the controller

              var scene3 = new ScrollMagic.Scene({
              //duration: 800,    // the scene should last for a scroll distance of 100px
                  offset: 300        // start this scene after scrolling for 50px
              //triggerElement: '.upcomingDemoEvents'
            })
            //.setTween(tween)
            //.setPin('.expCLocatedHero', {pushFollowers: false})
              .setClassToggle('#locationAddress', 'fade-out') // pins the element for the the scene's duration
            //.addIndicators({name: "fade"})
              .addTo(controller); // assign the scene to the controller

              var scene4 = new ScrollMagic.Scene({
              //duration: 800,    // the scene should last for a scroll distance of 100px
                  offset: 400        // start this scene after scrolling for 50px
              //triggerElement: '.upcomingDemoEvents'
            })
            //.setTween(tween)
            //.setPin('.expCLocatedHero', {pushFollowers: false})
              .setClassToggle(".LocatedHeroHeadline br", 'remove') // pins the element for the the scene's duration
            //.addIndicators({name: "fade"})
              .addTo(controller); // assign the scene to the controller
          //$(".LocatedHeroHeadline br").css({"display":"none"});
          /*
          // create a scene
          var scene = new ScrollMagic.Scene({
              duration: 400,    // the scene should last for a scroll distance of 100px
                  offset: 10       // start this scene after scrolling for 50px
              //triggerElement: '.expCLocatedHeroText',
              //triggerHook: 0,
              //duration: 400,
              //offset: 50 
              })
            //.setTween(tween)
              .setPin('.expCLocatedHeroText') // pins the element for the the scene's duration
            .addIndicators({name: "resize"})
              .addTo(controller); // assign the scene to the controller
            
          var scene1 = new ScrollMagic.Scene({
              //duration: 800,    // the scene should last for a scroll distance of 100px
                 // offset: 100        // start this scene after scrolling for 50px
              triggerElement: '.upcomingDemoEvents',
              triggerHook: 0.4
              //duration: 400
              
              })
            //.setTween(tween)
             
            .setClassToggle('#LocatedHeroHeadline', 'fade-out') // pins the element for the the scene's duration
            .addIndicators({name: "fade"})
              .addTo(controller); // assign the scene to the controller
            
          var scene1a = new ScrollMagic.Scene({
              //duration: 800,    // the scene should last for a scroll distance of 100px
                 // offset: 100        // start this scene after scrolling for 50px
              triggerElement: '.upcomingDemoEvents',
              triggerHook: 0.8
              //duration: 400
              
              })
            //.setTween(tween)
            .setPin('.expCLocatedHero', {pushFollowers: false})
            //.setClassToggle('#LocatedHeroHeadline', 'fade-out') // pins the element for the the scene's duration
            .addIndicators({name: "stick"})
              .addTo(controller); // assign the scene to the controller 
             
          var scene2 = new ScrollMagic.Scene({
              duration: 800,    // the scene should last for a scroll distance of 100px
                  offset: 100        // start this scene after scrolling for 50px
              //triggerElement: '.upcomingDemoEvents'
              })
            //.setTween(tween)
            .setPin('.expCLocatedHero', {pushFollowers: false})
              .setClassToggle('#heroHideSection', 'fade-out') // pins the element for the the scene's duration
            .addIndicators({name: "fade"})
              .addTo(controller); // assign the scene to the controller
            

              */



          /*
          // init controller  
          var controller = new ScrollMagic.Controller();

          // build tween
          var tween = TweenMax.to(".expCLocatedHeroText", 0.5, {scale: 3, ease: Linear.easeNone});

          // build scene
          new ScrollMagic.Scene({duration: 300, offset: 50})
                      .setTween(tween)
                      .setPin(".expCLocatedHero")
                      //.addIndicators({name: "resize"}) // add indicators (requires plugin)
                      .addTo(controller);
            
                      */


                    });


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
